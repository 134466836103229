.container{
    width: 750px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 768px) {
    .container{
        margin-top: 1rem;
        width: 100%;
    }
}

.background{
    background-image: url("../../assets/peso.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 300px;
    filter: blur(4rem);
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;

}

.content{
    z-index: 1;
    width: 100%;
    height: 100%;
}