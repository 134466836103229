.head{
    text-align: center;
    width: 100%;
}

.head img {
    width: 125px;
    height: auto;
    border-radius: 100%;
    box-shadow: var(--shadow);
}

.head h1{
    font-size: 1.3rem;
    font-weight: bold;
    margin: 1.5rem 0rem;
}

.head h2{
    font-size: 1.2rem;
    opacity: .70;

}

.head h3{
    margin-top: 1rem;
    font-size: 1.1rem;
    color: #85F689;
}

.head h4{
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 2rem;
}

.head .button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0rem;
    background-color: black;
    width: 100%;
    height: 50px;
    position: relative;
    border-radius: .8rem;
    box-shadow: var(--shadow);
}

.head .button img {
    width: 40px;
    position: absolute;
    left: .5rem;
}

.button svg{
    color: #f1f1f1;
    font-size: 2rem;
    position: absolute;
    left: 1rem;
}