.footer{
    text-align: center;
}

.footer svg{
    font-size: 2.2rem;
    margin-right: .5rem;
}

.footer p{
    font-size: .8rem;
    margin-top: 5rem;
    margin-bottom: .5rem;
}